<template>
  <section class="new-event relative">
    <div class="block md:flex justify-between">
      <h1 class="h1-Alternative uppercase text-center mb-3 md:mb-0">New Event Template</h1>
    </div>

    <div class="mt-5 lg:mt-10">
      <div class="bg-white dark:bg-dark-bgSecondary border-t-2 border-grey-light dark:border-dark-textSecondary">
        <div class="flex flex-col min-w-0 break-words w-full">
          <div class="flex-auto dark:text-dark-textPrimary">
            <Form v-slot="{ errors }" :validation-schema="schema" class="flex flex-col font-fs-elliot-pro py-5 mb-10 md:mb-0" @submit="onSubmit">
              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/4">
                  <label for="event-name" class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Name: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <Field
                    id="event-name"
                    v-model="payload.name"
                    name="name"
                    type="text"
                    :class="{
                      'text-danger border border-danger': errors.name,
                    }"
                    class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-lg focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                  />
                  <p class="text-danger dark:text-danger text-base italic">
                    {{ errors.name }}
                  </p>
                </div>
              </div>
              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/4">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Login Mode: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <ComboBox
                    class="w-full"
                    v-model="data.loginMode"
                    :options="[
                      { label: 'Public', value: 'PUBLIC' },
                      { label: 'Pre-registered Viewer Logins', value: 'CUSTOM_LIST' },
                    ]"
                  />
                </div>
              </div>
              <div class="flex my-2 md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/4">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Allow anonymous login: </label>
                </div>
                <div class="w-2/3 flex flex-col">
                  <Switch v-model="settings.isAnonymousLogin" class="mb-2 self-start" />
                </div>
              </div>
              <div class="flex my-2 md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/4">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Password Protection: </label>
                </div>
                <div class="w-2/3 flex flex-col">
                  <Switch v-model="settings.isProtect" class="mb-2 self-start" />
                  <template v-if="settings.isProtect">
                    <Field
                      v-model="settings.password"
                      type="text"
                      name="password"
                      rows="5"
                      :class="{
                        'text-danger border border-danger': errors['password'],
                      }"
                      class="
                        w-full
                        text-xs
                        md:text-base
                        text-black-1
                        dark:text-dark-textPrimary
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        rounded
                        p-2
                        md:p-3
                      "
                    />
                    <p class="text-danger dark:text-danger text-base italic">
                      {{ errors["password"] }}
                    </p>
                  </template>
                </div>
              </div>

              <div class="flex items-start flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/4">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Paid event: </label>
                </div>
                <div class="pl-2 md:pl-0 w-2/3 flex">
                  <Switch v-model="data.isPaidEvent" />
                  <template v-if="data.isPaidEvent">
                    <Field
                      v-model="data.price"
                      type="text"
                      name="price"
                      rows="5"
                      :class="{
                        'text-danger border border-danger': errors['price'],
                      }"
                      class="
                        w-full
                        text-xs
                        md:text-base
                        text-black-1
                        dark:text-dark-textPrimary
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        rounded
                        p-2
                        md:p-3
                        ml-2
                      "
                    />
                    <p class="text-danger dark:text-danger text-base italic">
                      {{ errors["price"] }}
                    </p>
                  </template>
                </div>
              </div>
              <div class="flex mb-7 lg:mb-8">
                <div class="md:w-1/3 lg:w-1/4">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Ticker Bar Message: </label>
                </div>
                <div class="ml-4 md:ml-0 md:w-2/3 mt-2 md:mt-0">
                  <Switch v-model="data.isShowTicker" class="mb-2 self-start" />
                </div>
              </div>
              <template v-if="data.isShowTicker">
                <div class="flex items-center mb-7 lg:mb-8">
                  <div class="md:w-1/3 lg:w-1/4">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Ticker Bar: </label>
                  </div>
                  <div class="w-full md:w-2/3 mt-2 md:mt-0">
                    <ComboBox
                      class="w-full"
                      v-model="data.eventMode"
                      :options="[
                        { label: 'Message', value: 'MESSAGE' },
                        { label: 'Isi API Data', value: 'DONATE' },
                      ]"
                    />
                  </div>
                </div>
                <div v-if="data.eventMode === 'MESSAGE'" class="flex items-center mb-7 lg:mb-8">
                  <div class="md:w-1/3 lg:w-1/4">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Message: </label>
                  </div>
                  <div class="w-full md:w-2/3 mt-2 md:mt-0">
                    <Field
                      name="eventModeMessage"
                      v-model="payload.eventModeMessage"
                      as="textarea"
                      class="
                        w-full
                        text-xs
                        md:text-base
                        text-black-1
                        dark:text-dark-textPrimary
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        rounded
                        p-2
                        md:p-3
                      "
                    />
                    <p class="text-danger dark:text-danger text-base italic">
                      {{ errors["eventModeMessage"] }}
                    </p>
                  </div>
                </div>
              </template>

              <div class="flex flex-col md:flex-row mt-2 md:mt-0 md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/4">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Primary color: </label>
                </div>
                <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 md:w-auto lg:w-2/3">
                  <div class="relative flex items-center">
                    <span class="absolute left-4 text-lg font-semibold text-grey-mid"># &nbsp; {{ payload.primaryColour.substring(1) }}</span>

                    <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

                    <Field
                      v-model="payload.primaryColour"
                      name="primaryColor"
                      type="color"
                      class="
                        h-12
                        w-52
                        md:w-60
                        text-lg text-left
                        font-semibold
                        appearance-none
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        text-grey-dark
                        dark:text-dark-textPrimary
                        border border-grey-mid
                        focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta
                        rounded
                        p-0.5
                        pl-11.75
                        pr-1
                      "
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col md:flex-row mt-2 md:mt-0 md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/4">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Secondary color: </label>
                </div>
                <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 md:w-auto lg:w-2/3">
                  <div class="relative flex items-center">
                    <span class="absolute left-4 text-lg font-semibold text-grey-mid"># &nbsp; {{ payload.secondaryColor.substring(1) }}</span>

                    <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

                    <Field
                      v-model="payload.secondaryColor"
                      name="secondaryColor"
                      type="color"
                      class="
                        h-12
                        w-52
                        md:w-60
                        text-lg text-left
                        font-semibold
                        appearance-none
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        text-grey-dark
                        dark:text-dark-textPrimary
                        border border-grey-mid
                        focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta
                        rounded
                        p-0.5
                        pl-11.75
                        pr-1
                      "
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col md:flex-row mt-2 md:mt-0 md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/4">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold"> Font: </label>
                </div>
                <div class="md:flex md:items-center w-2/3 mt-2 md:mt-0 lg:w-2/3">
                  <ComboBox
                    class="w-full"
                    v-model="data.currentFont"
                    :options="[
                      { label: 'Mulish', value: `'Mulish', sans-serif` },
                      { label: 'Inconsolata', value: `'Inconsolata', monospace` },
                      { label: 'Montserrat', value: `'Montserrat', sans-serif` },
                      { label: 'Noto Sans Mono', value: `'Noto Sans Mono', monospace` },
                      { label: 'Poppins', value: `'Poppins', sans-serif` },
                      { label: 'Roboto', value: `'Roboto', sans-serif` },
                      { label: 'Local font', value: '' },
                      {
                        label: 'Open Sans',
                        value: `'Open Sans', sans-serif`,
                      },
                    ]"
                  />
                </div>
              </div>

              <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                <div class="w-full md:w-1/3 lg:w-1/4">
                  <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold"> Custom css: </label>
                </div>
                <div class="w-full md:w-2/3 mt-2 md:mt-0">
                  <Field
                    v-model="payload.customCSS"
                    as="textarea"
                    name="customCSS"
                    rows="5"
                    class="
                      w-full
                      text-xs
                      md:text-base
                      text-black-1
                      dark:text-dark-textPrimary
                      bg-off-white-1
                      dark:bg-dark-bgPrimary
                      border border-grey-mid
                      focus:ring-magenta focus:border-magenta
                      rounded
                      p-2
                      md:p-3
                    "
                  />
                </div>
              </div>

              <div class="relative border-t p-2 pt-4 mt-10 md:mt-2">
                <span class="absolute -top-8 left-2 text-lg font-montserrat font-semibold text-magenta"> Pre-event </span>
                <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                  <div class="w-full md:w-1/3 lg:w-1/4">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Background color: </label>
                  </div>
                  <div class="md:flex md:items-center w-full md:w-2/3 mt-2 md:mt-0">
                    <div class="relative flex items-center">
                      <span class="absolute left-4 text-lg font-semibold text-grey-mid">
                        # &nbsp;
                        {{ payload.preEventConfig.bgColor.substring(1) }}
                        <!-- {{ payload.preEventConfig.bgColor }} -->
                      </span>

                      <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

                      <Field
                        v-model="payload.preEventConfig.bgColor"
                        name="preEventBackgroundColor"
                        type="color"
                        class="
                          h-12
                          w-52
                          md:w-60
                          text-lg text-left
                          font-semibold
                          appearance-none
                          bg-off-white-1
                          dark:bg-dark-bgPrimary
                          text-grey-dark
                          dark:text-dark-textPrimary
                          border border-grey-mid
                          focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta
                          rounded
                          p-0.5
                          pl-11.75
                          pr-1
                        "
                      />
                    </div>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                  <div class="w-full md:w-1/3 lg:w-1/4 md:pt-0">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Background Image: </label>
                  </div>
                  <div class="md:flex md:items-center w-full md:w-2/3 mt-2 md:mt-0">
                    <label
                      :class="{
                        'py-7': empty(images.preEventBackgroundImage),
                      }"
                      class="
                        w-60
                        lg:w-60
                        h-20
                        lg:h-24
                        flex flex-col
                        relative
                        items-center
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        text-black-1
                        dark:text-dark-textPrimary
                        rounded-lg
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        cursor-pointer
                        md:ml-0
                      "
                    >
                      <QRCodeLogo v-if="empty(images.preEventBackgroundImage)" class="h-8 w-8" />
                      <img v-else :src="images.preEventBackgroundImage" alt="Background Image" class="h-full" />
                      <input name="preEventBackgroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
                    </label>
                    <template v-if="!empty(images.preEventBackgroundImage)">
                      <button
                        type="button"
                        class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
                        @click="handleDeleteFilesUpload('preEventBackgroundImage')"
                      >
                        <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
                        <span>Delete</span>
                      </button>
                    </template>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                  <div class="w-full md:w-1/3 lg:w-1/4 md:pt-0">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Foreground Image: </label>
                  </div>
                  <div class="md:flex md:items-center w-full md:w-2/3 mt-2 md:mt-0">
                    <label
                      :class="{
                        'py-7': empty(images.preEventForegroundImage),
                      }"
                      class="
                        w-60
                        lg:w-60
                        h-20
                        lg:h-24
                        flex flex-col
                        relative
                        items-center
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        text-black-1
                        dark:text-dark-textPrimary
                        rounded-lg
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        cursor-pointer
                        md:ml-0
                      "
                    >
                      <QRCodeLogo v-if="empty(images.preEventForegroundImage)" class="h-8 w-8" />
                      <img v-else :src="images.preEventForegroundImage" alt="Background Image" class="h-full" />
                      <input name="preEventForegroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
                    </label>
                    <template v-if="!empty(images.preEventForegroundImage)">
                      <button
                        type="button"
                        class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
                        @click="handleDeleteFilesUpload('preEventForegroundImage')"
                      >
                        <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
                        <span>Delete</span>
                      </button>
                    </template>
                  </div>
                </div>

                <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                  <div class="w-full md:w-1/3 lg:w-1/4">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Message: </label>
                  </div>

                  <div class="w-full md:w-2/3 mt-2 md:mt-0">
                    <Field
                      v-model="payload.preEventConfig.text"
                      name="preEventMessage"
                      as="textarea"
                      :class="{
                        'text-danger border border-danger': errors['preEventMessage'],
                      }"
                      class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                    />
                    <p class="text-danger dark:text-danger text-base italic">
                      {{ errors["preEventMessage"] }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="relative border-t p-2 pt-4 mt-8 md:mt-0">
                <span class="absolute -top-8 left-2 text-lg font-montserrat font-semibold text-magenta"> Post-event </span>
                <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                  <div class="w-full md:w-1/3 lg:w-1/4">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Background color: </label>
                  </div>
                  <div class="md:flex md:items-center w-full md:w-2/3 mt-2 md:mt-0">
                    <div class="relative flex items-center">
                      <span class="absolute left-4 text-lg font-semibold text-grey-mid">
                        # &nbsp;
                        {{ payload.postEventConfig.bgColor.substring(1) }}
                        <!-- {{ payload.postEventBackgroundColor }} -->
                      </span>

                      <span class="absolute left-11.5 w-1px h-12 bg-grey-mid"></span>

                      <Field
                        v-model="payload.postEventConfig.bgColor"
                        name="postEventBackgroundColor"
                        type="color"
                        class="
                          h-12
                          w-52
                          md:w-60
                          text-lg text-left
                          font-semibold
                          appearance-none
                          bg-off-white-1
                          dark:bg-dark-bgPrimary
                          text-grey-dark
                          dark:text-dark-textPrimary
                          border border-grey-mid
                          focus:border-magenta focus:outline-none focus:ring-1 focus:ring-magenta
                          rounded
                          p-0.5
                          pl-11.75
                          pr-1
                        "
                      />
                    </div>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                  <div class="w-full md:w-1/3 lg:w-1/4 md:pt-0">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Background Image: </label>
                  </div>
                  <div class="md:flex md:items-center w-full md:w-2/3 mt-2 md:mt-0">
                    <label
                      :class="{
                        'py-7': empty(images.postEventBackgroundImage),
                      }"
                      class="
                        w-60
                        lg:w-60
                        h-20
                        lg:h-24
                        flex flex-col
                        relative
                        items-center
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        text-black-1
                        dark:text-dark-textPrimary
                        rounded-lg
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        cursor-pointer
                        md:ml-0
                      "
                    >
                      <QRCodeLogo v-if="empty(images.postEventBackgroundImage)" class="h-8 w-8" />
                      <img v-else :src="images.postEventBackgroundImage" alt="Background Image" class="h-full" />
                      <input name="postEventBackgroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
                    </label>
                    <template v-if="!empty(images.postEventBackgroundImage)">
                      <button
                        type="button"
                        class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
                        @click="handleDeleteFilesUpload('postEventBackgroundImage')"
                      >
                        <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
                        <span>Delete</span>
                      </button>
                    </template>
                  </div>
                </div>

                <div class="flex flex-col md:flex-row md:mb-7 lg:mb-5">
                  <div class="w-full md:w-1/3 lg:w-1/4 md:pt-0">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-lg font-montserrat font-semibold pt-2.5"> Foreground Image: </label>
                  </div>
                  <div class="md:flex md:items-center w-full md:w-2/3 mt-2 md:mt-0">
                    <label
                      :class="{
                        'py-7': empty(images.postEventForegroundImage),
                      }"
                      class="
                        w-60
                        lg:w-60
                        h-20
                        lg:h-24
                        flex flex-col
                        relative
                        items-center
                        bg-off-white-1
                        dark:bg-dark-bgPrimary
                        text-black-1
                        dark:text-dark-textPrimary
                        rounded-lg
                        border border-grey-mid
                        focus:ring-magenta focus:border-magenta
                        cursor-pointer
                        md:ml-0
                      "
                    >
                      <QRCodeLogo v-if="empty(images.postEventForegroundImage)" class="h-8 w-8" />
                      <img v-else :src="images.postEventForegroundImage" alt="Background Image" class="h-full" />
                      <input name="postEventForegroundImage" type="file" class="hidden" v-on:change="handleChangeFilesUpload" accept="image/png, image/jpeg" />
                    </label>
                    <template v-if="!empty(images.postEventForegroundImage)">
                      <button
                        type="button"
                        class="bg-transparent text-lg lg:text-xl text-magenta font-bold inline-flex items-center border-none overflow-hidden focus:outline-none p-2 lg:px-4"
                        @click="handleDeleteFilesUpload('postEventForegroundImage')"
                      >
                        <DeleteIcon class="w-6 h-6 mr-1 lg:mr-2" />
                        <span>Delete</span>
                      </button>
                    </template>
                  </div>
                </div>

                <div class="flex items-center flex-col md:flex-row md:mb-7 lg:mb-8">
                  <div class="w-full md:w-1/3 lg:w-1/4">
                    <label class="block capitalize tracking-wide text-grey-mid text-xs md:text-base font-montserrat font-semibold pt-2.5"> Message: </label>
                  </div>

                  <div class="w-full md:w-2/3 mt-2 md:mt-0">
                    <Field
                      v-model="payload.postEventConfig.text"
                      name="postEventMessage"
                      as="textarea"
                      :class="{
                        'text-danger border border-danger': errors['postEventMessage'],
                      }"
                      class="block w-full appearance-none bg-off-white-1 dark:bg-dark-bgPrimary text-xs md:text-base focus:ring-magenta focus:border-magenta rounded py-2 md:py-3 px-2 md:px-4 mb-3"
                    />
                    <p class="text-danger dark:text-danger text-base italic">
                      {{ errors["postEventMessage"] }}
                    </p>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                class="button_large-Alternative absolute right-0 bottom-0 md:bottom-auto md:-top-4 uppercase bg-magenta border rounded-full py-3 lg:py-4 lg:px-8 focus:outline-none mb-4 md:mb-0"
              >
                Create
              </button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { reactive, ref } from "vue";

import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { TweenMax } from "gsap";
import * as _ from "lodash";
import axios from "axios";

import { sleep } from "@Helpers/sleep";
import { getMomentT } from "@Helpers/date";
import { getS3UploadKey } from "@API/event/event-repository";
import { postEventTemplate } from "@API/event/template-repository";

import DeleteIcon from "@Assets/images/DeleteIcon";
import QRCodeLogo from "@Assets/images/QRCodeLogo";
import Switch from "@/components/layouts/Switch.vue";
import ComboBox from "@/components/layouts/ComboBox";

import { empty, isFile } from "@/utils";
export default {
  name: "NewEventTemplate",
  components: {
    Form,
    Field,
    DeleteIcon,
    QRCodeLogo,
    Switch,
    ComboBox,
  },
  setup() {
    const data = reactive({
      currentFont: "",
      isPaidEvent: false,
      isShowTicker: false,
      eventMode: "MESSAGE",
      price: 0,
      loginMode: "PUBLIC",
    });
    const settings = reactive({
      isProtect: false,
      password: "",
    });

    let dropdown = ref(null);
    const schema = Yup.object().shape({
      password: Yup.lazy(() => (settings.isProtect ? Yup.string().required("Password is required") : Yup.string())),
      price: Yup.lazy(() => (data.isPaidEvent ? Yup.string().required("Price is required") : Yup.string())),
      eventModeMessage: Yup.lazy(() => (data.isShowTicker && data.eventMode === "MESSAGE" ? Yup.string().required("Message is required") : Yup.string())),
    });

    return {
      settings,
      data,
      dropdown,
      schema,
    };
  },
  data() {
    return {
      filesUpload: {
        preEventBackgroundImage: null,
        preEventForegroundImage: null,
        postEventBackgroundImage: null,
        postEventForegroundImage: null,
      },
      images: {
        preEventBackgroundImage: null,
        preEventForegroundImage: null,
        postEventBackgroundImage: null,
        postEventForegroundImage: null,
      },
      imagesPayload: {
        preEventBackgroundImage: null,
        preEventForegroundImage: null,
        postEventBackgroundImage: null,
        postEventForegroundImage: null,
      },
      payload: {
        id: undefined,
        primaryColour: "#ffffff",
        secondaryColor: "#ffffff",
        font: "",
        preEventConfig: {
          bgColor: "#ffffff",
          bgImage: "",
          bgForegroundImage: "",
          text: "",
        },
        postEventConfig: {
          bgColor: "#ffffff",
          bgImage: "",
          bgForegroundImage: "",
          text: "",
        },
      },
    };
  },

  methods: {
    empty,
    isFile,
    handleDeleteFilesUpload(property, index) {
      this.filesUpload[property] = null;
      this.images[property] = null;
    },
    handleChangeFilesUpload(e, index) {
      const fileSelected = e.target.files[0];
      let reader = new FileReader();
      reader.onload = event => {
        this.filesUpload[e.target.name] = fileSelected;
        this.images[e.target.name] = event.target.result;
      };
      reader.readAsDataURL(fileSelected);
    },
    async doUpdateFile(file, property) {
      if (!file.type.includes("image/")) return;

      const s3Result = await getS3UploadKey(file.type.replace("image/", ""));

      const {
        data: { fields, url },
      } = s3Result;

      const formData = new FormData();

      Object.keys(fields).forEach(key => {
        formData.append(key, fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("file", file);

      const result = await axios.post(url, formData, {});

      if (result.status === 204) {
        this.imagesPayload[property] = fields.key;
      }

      // Show Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: true,
        status: result.status === 204,
        // title: result.status === 204 ? "Success!" : "Failed!",
        // message: `[${_.startCase(_.camelCase(property))}] ${
        //   result.status === 204
        //     ? "Upload file successfully!"
        //     : "Upload file failed!"
        // }`,
        title: `[${result.status === 204 ? "Success!" : "Failed!"}] ${_.startCase(_.camelCase(property))}`,
        message: result.status === 204 ? "Upload file successfully!" : "Upload file failed!",
      });

      await sleep(1600);

      // Hide Alert
      this.$store.dispatch("toggleAlert", {
        isAlert: false,
      });
    },
    async handleUploadFiles(property) {
      // this.$store.dispatch("toggleLoading");
      this.$store.dispatch("updateLoadingText", "Uploading " + _.startCase(_.camelCase(property)));

      await this.doUpdateFile(this.filesUpload[property], property);
      // this.$store.dispatch("toggleLoading");
    },
    async onSubmit() {
      try {
        this.$store.dispatch("toggleLoading");
        const payload = {
          ...this.payload,
          font: this.data.currentFont,
          settings: {
            ...this.settings,
            bottomBar: this.data.isShowTicker ? "SHOW" : "HIDE",
          },
          // eventMode: this.data.eventMode,
          eventMode: "MESSAGE",
          loginMode: this.data.loginMode,
          price: this.data.isPaidEvent ? this.data.price : 0,
          isPaidEvent: this.data.isPaidEvent,
        };

        _.forOwn(payload, (value, key) => {
          if (key.includes("Url") && !value.url.length) delete payload[key];
        });
        const filesUploadKeys = _.keys(this.filesUpload);

        for (let i = 0; i < filesUploadKeys.length; i++) {
          if (isFile(this.filesUpload[filesUploadKeys[i]]) || !empty(this.filesUpload[filesUploadKeys[i]])) {
            await this.handleUploadFiles(filesUploadKeys[i]);
          }
        }

        const dataSubmit = {
          ...payload,
          preEventConfig: {
            ...payload.preEventConfig,
            bgImage: this.imagesPayload.preEventBackgroundImage,
            bgForegroundImage: this.imagesPayload.preEventForegroundImage,
          },
          postEventConfig: {
            ...payload.postEventConfig,
            bgImage: this.imagesPayload.postEventBackgroundImage,
            bgForegroundImage: this.imagesPayload.postEventForegroundImage,
          },
        };

        delete this.imagesPayload.preEventBackgroundImage;
        delete this.imagesPayload.preEventForegroundImage;
        delete this.imagesPayload.postEventBackgroundImage;
        delete this.imagesPayload.postEventForegroundImage;

        this.$store.dispatch("updateLoadingText", "Creating new event template");
        const result = await postEventTemplate({
          ...dataSubmit,
          ...this.imagesPayload,
        });
        console.log({
          ...dataSubmit,
          ...this.imagesPayload,
        });

        // Show Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: true,
          status: result.success,
          title: result.success ? "Success!" : "Failed!",
          message: result.message,
        });

        if (result.success) {
          this.$router.push({
            name: "EventTemplateList",
          });
        }
      } catch (error) {
        console.log({ error });
      } finally {
        this.$store.dispatch("closeLoading");

        await sleep(5000);
        // Hide Alert
        this.$store.dispatch("toggleAlert", {
          isAlert: false,
        });
      }
    },
  },
};
</script>
